import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, withRouter } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import {
  AppBar, Box, Button, Container, CssBaseline, Divider,
  Drawer, Grid, IconButton, Link, List, ListItem, ListItemIcon,
  ListItemText, makeStyles, Paper, Toolbar, Typography,
} from '@material-ui/core';

import clsx from 'clsx';

import { API, graphqlOperation } from 'aws-amplify';

import Welcome from '../welcome';
import Schedule from '../schedule';
import Closet from '../closet';
import { SpreeContext } from '../../../context';

import Logo from '../../../images/PS_logo_white.svg';
import SideLink from './SideLink';
import { useInterval } from '../../../hooks';
import { deleteCookies } from '../../../utils/cookie';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const [cookies, setCookie] = useCookies();
  const [open, setOpen] = useState(false);
  const [closet, setCloset] = useState([]);
  const [spree, setSpree] = useState({ goal: '[]', schedule: null, closet: '[]' });

  useEffect(() => {
    if (cookies.user) {
      (async function fetchSpree() {
        const { data: { getPoshingSpree } } = await API.graphql(graphqlOperation(`
            query GetAttributes($email: AWSEmail!) {
              getPoshingSpree(email: $email) { 
                username 
                closet
                schedule
                goal
              }
            }
          `, { email: cookies.user.email }));

        setSpree(getPoshingSpree);
        setCloset(JSON.parse(getPoshingSpree.closet));
      }());
    }
  }, []);

  if (!cookies.user) {
    return <Redirect to="/login" />;
  }

  return (
    <SpreeContext.Provider value={{ spree, closet, setCloset }}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => { setOpen(true); }}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>

            <img width="115px" alt="logo" src={Logo} />

            <IconButton
              style={{ right: 0, position: 'absolute', marginRight: '1em' }}
              onClick={() => {
                deleteCookies(); // actually deletes the cookies
                setCookie('user', ''); // triggers rerender
              }}
              color="inherit"
            >
              <Typography component="h1" variant="h6" color="inherit">
                Logout
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={() => { setOpen(false); }}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              <SideLink to="/dashboard" Icon={<DashboardIcon />} label="Dashboard" />
              <SideLink to="/dashboard/schedule" Icon={<ScheduleIcon />} label="Schedule" />
              <SideLink to="/dashboard/closet" Icon={<ShoppingBasketIcon />} label="Closet" />
            </div>
          </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Route path={['/dashboard/schedule']} component={Schedule} />
            <Route path={['/dashboard/closet']} component={Closet} />
            <Route exact path={['/dashboard', '/']} component={Welcome} />
          </Container>
        </main>
      </div>
    </SpreeContext.Provider>
  );
}
