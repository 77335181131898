import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SideLink({ Icon, to, label }) {
  return (
    <Link to={to}>
      <ListItem button>
        <ListItemIcon>
          {Icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </Link>
  );
}
