import React, { useContext, useState } from 'react';

import { API, graphqlOperation } from 'aws-amplify';

import {
  Container, createMuiTheme, makeStyles, ThemeProvider,
  CssBaseline, AppBar, Toolbar, Typography,
  Button, TextField,
} from '@material-ui/core';

import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import PoshMarkLogo from '../../../images/PoshmarkLogo.png';
import { SignUpContext } from '../../../context';

const poshMarkTheme = createMuiTheme({
  palette: {
    primary: { main: '#623039' }, // Poshmark red
    secondary: { main: '#ffffff' }, // white
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    height: '55px',
    boxShadow: '0 2px 2px rgba(0,0,0,0.2)',
    backgroundColor: 'rgba(255,255,255,0.95)',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function Credentials({ history }) {
  const classes = useStyles();
  const [cookies] = useCookies();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <ThemeProvider theme={poshMarkTheme}>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar style={{ padding: '0 3%' }}>
          <Typography variant="h6" color="inherit" noWrap>
            <img height={24} width={135} src={PoshMarkLogo} alt="" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Container style={{ height: '100%' }} component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography align="center" component="h1" variant="h5">
            Last step! Let's connect your Poshmark
          </Typography>
          <form
            onSubmit={async (event) => {
              event.preventDefault();

              await API.graphql(graphqlOperation(`
                mutation LoginPosher($input: PoshingSpreeCredentials!) {
                  loginPosher(input: $input)
                }              
              `, {
                input: {
                  email: cookies.user.email,
                  username,
                  password,
                },
              }));

              history.push('/dashboard');
            }}
            className={classes.form}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={({ target }) => setUsername(target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={({ target }) => setPassword(target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Connect
            </Button>
          </form>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default withRouter(Credentials);
