import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import {
  Button, Card, CardActions, CardContent,
  CardMedia, Grid, Typography,
} from '@material-ui/core';

export default SortableElement(({ item }) => (
  <Grid
    style={{ userSelect: 'none' }}
    item
    key={item.id}
    xs={12}
    sm={6}
    md={3}
  >
    <Card style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <CardMedia
        style={{
          paddingTop: '100%', // 16:9
        }}
        image={item.picture_url}
        title={item.title}
      />
      <CardContent style={{ flexGrow: 1 }}>
        <Typography gutterBottom style={{ fontSize: '1em' }} component="h1">
          {item.title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary">
          View
        </Button>
        <Button size="small" color="primary">
          Edit
        </Button>
      </CardActions>
    </Card>
  </Grid>
));
