import React, { useContext, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';

import {
  Box, Grid, Container, Button, TextField,
  Select, MenuItem, InputLabel, FormControl,
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { useCookies } from 'react-cookie';
import { PulseLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';
import Shopping from '../../../images/Shopping.jpg';

function Register({ history }) {
  const cookieFunctions = useCookies();
  const setCookie = cookieFunctions[1];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [password, setPassword] = useState('');
  const [birthdate, setBirthdate] = useState('');

  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loggingInState, setLoggingInState] = useState(false);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={5}
                      className="d-flex align-items-center"
                    >
                      <div className="hero-wrapper w-100 bg-composed-wrapper min-vh-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{ backgroundImage: `url(${Shopping})` }}
                          />
                          <div className="bg-composed-wrapper--bg" />
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      lg={7}
                      className="d-flex align-items-center"
                    >
                      <Container maxWidth="sm">
                        <Box flexDirection="row" display="flex">
                          <h3 className="display-4 mb-2 font-weight-bold">
                            Let's get you signed up
                          </h3>
                          {loggingInState && <Box paddingTop="0.5em" paddingLeft="0.8em"><PulseLoader /></Box>}
                        </Box>
                        <p className="font-size-lg mb-5 text-black-50">
                          We're over the moon about this!
                        </p>
                        <form onSubmit={async (event) => {
                          event.preventDefault();

                          setLoggingInState(true);

                          try {
                            await Auth.signUp({
                              username: email,
                              password,
                              attributes: {
                                email,
                                birthdate,
                                gender,
                                name,
                              },
                            });

                            await Auth.signIn(email, password);

                            setCookie('user', {
                              email, birthdate, gender, name,
                            });

                            await API.graphql(graphqlOperation(`
                              mutation createPoshingSpree($input: CreatePoshingSpreeInput!) {
                                createPoshingSpree(input: $input) {
                                  email
                                }
                              }
                            `, { input: { email } }));

                            history.push('/plan');
                          } catch (error) {
                            setErrorState(true);
                            setLoggingInState(false);

                            switch (error.code) {
                              case 'InvalidParameterException':
                                return setErrorMessage("That password isn't quite strong enough! Try to make it longer and less common. We're all about safety around here");

                              case 'UsernameExistsException':
                                return setErrorMessage(
                                  <p>
                                    Hey there, we already have an account for that email.
                                    {' '}
                                    <a href="/login">Try to login!</a>
                                    {' '}
                                    Or, we can also help you
                                    {' '}
                                    <a href="/reset-password">reset your password.</a>
                                    {' '}
                                    Even elephants forget sometimes.
                                  </p>,
                                );

                              default:
                                return setErrorMessage('Something went wrong! Please try again in a bit');
                            }
                          }

                          history.push('/Plan');
                        }}
                        >
                          <div className="mb-3">
                            <FormControl fullWidth required>
                              <TextField
                                variant="outlined"
                                color="primary"
                                onChange={({ target }) => setEmail(target.value)}
                                label="Email address"
                                placeholder="Enter your email address"
                                type="email"
                              />
                            </FormControl>
                          </div>
                          <div className="mb-3">
                            <FormControl fullWidth required>
                              <TextField
                                variant="outlined"
                                color="primary"
                                onChange={({ target }) => setPassword(target.value)}
                                label="Password"
                                placeholder="Enter your password"
                                type="password"
                              />
                            </FormControl>
                          </div>
                          <div className="mb-3">
                            <FormControl fullWidth required>
                              <TextField
                                required
                                variant="outlined"
                                color="primary"
                                onChange={({ target }) => setName(target.value)}
                                label="Full name"
                                placeholder="Enter your full name"
                                type="text"
                              />
                            </FormControl>
                          </div>
                          <div className="mb-3">
                            <FormControl fullWidth required>
                              <TextField
                                variant="outlined"
                                onChange={({ target }) => setBirthdate(target.value)}
                                color="primary"
                                label="Birthday"
                                type="date"
                                defaultValue=""
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </div>
                          <div className="mb-3">
                            <FormControl fullWidth required variant="outlined">
                              <InputLabel id="gender-label">Gender</InputLabel>
                              <Select
                                onChange={({ target }) => setGender(target.value)}
                                value={gender}
                                labelId="gender-label"
                                label="Gender"
                              >
                                <MenuItem value="">Prefer not to share</MenuItem>
                                <MenuItem value="Male"> Male</MenuItem>
                                <MenuItem value="Female"> Female</MenuItem>
                                <MenuItem value="Non-Binary"> Non-Binary</MenuItem>
                                <MenuItem value="Not listed"> Not listed</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="form-group pt-2 mb-4">
                            By clicking the
                            {' '}
                            <strong>Create account</strong>
                            {' '}
                            button below you agree to our terms of service and
                            privacy statement.
                          </div>

                          <Button
                            type="submit"
                            color="primary"
                            size="large"
                            variant="contained"
                            className="mb-5"
                          >
                            Create Account
                          </Button>
                        </form>
                        {errorState && (
                          <MuiAlert className="mb-4" severity="error">
                            <div className="d-flex align-items-center align-content-center">
                              <span>
                                <strong className="d-block">Whoops!</strong>
                                {errorMessage}
                              </span>
                            </div>
                          </MuiAlert>
                        )}
                      </Container>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Register);
