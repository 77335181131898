import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import { Box, Card } from '@material-ui/core';

import moment from 'moment';
import Chart from 'react-apexcharts';

import { API, graphqlOperation } from 'aws-amplify';

import { useInterval } from '../../../hooks';
import { convertSchedule, TO_LOCAL } from '../../../utils/date';

const options = {
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    colors: ['#FC2F89', '#35e322'],
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.7,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  colors: ['#FC2F89', '#35e322'],
  stroke: {
    colors: ['#FC2F89', '#35e322'],
    curve: 'smooth',
    width: 2,
    dashArray: [0, 10],
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'category',
    categories: [...new Array(25).keys()].map((hour) => (
      moment().startOf('day').add(hour % 24, 'hours').format('h:mm a')
    )),
    crosshairs: {
      width: 1,
    },
  },
  yaxis: {
    min: 0,
  },
};

export default function ShareChart() {
  const [cookies] = useCookies();
  const [pastShares, setPastShares] = useState([]);
  const [upcomingShares, setUpcomingShares] = useState([]);

  useInterval(() => {
    (async function checkLoginStatus() {
      const { data: { getPoshingSpree: spree } } = await API.graphql(graphqlOperation(`
          query MyQuery($email: AWSEmail!) {
            getPoshingSpree(email: $email) {
              shares
              schedule
              goal
              closet
            }
          }`, { email: cookies.user.email }));

      const closet = JSON.parse(spree.closet);
      const goal = JSON.parse(spree.goal);
      const shares = JSON.parse(spree.shares);
      const schedule = convertSchedule(JSON.parse(spree.schedule), TO_LOCAL);

      // convert moment dow to python datetime... ugh
      const upcomingHours = schedule[(moment().weekday() + 6) % 7].filter(
        (hour) => hour >= moment().hour(),
      );

      const offsetTZ = -moment().utcOffset() / 60;
      const sharesTZ = shares.slice(offsetTZ).concat(shares.slice(0, offsetTZ));
      setPastShares(sharesTZ.concat(0));
      setUpcomingShares([...new Array(24).keys()].map((hour) => {
        if (!upcomingHours.includes(hour)) { return 0; }

        const randomNudge = Math.sign(Math.random() - 0.5) * Math.floor(Math.random() * 5);
        return goal.length * closet.length + randomNudge;
      }).concat(0));
    }());
  }, 10000);

  return (
    <Card elevation={3} className="mb-4">
      <div className="card-header-alt px-4 pt-4 pb-0">
        <h6 className="font-weight-bold font-size-lg mb-1 text-black">
          {pastShares.reduce((shares, hour) => shares + hour, 0)}
        </h6>
        <p className="text-black-50 mb-0">
          Shares in the past 24 hours
        </p>
      </div>

      <Chart
        height="500px"
        options={options}
        series={[{ name: 'Shares', data: pastShares }, { name: 'Projected Shares', data: upcomingShares }]}
        type="area"
      />
    </Card>

  );
}
