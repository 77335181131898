import React, { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { API, graphqlOperation } from 'aws-amplify';

import {
  Grid, Container, Typography, Button, makeStyles, Card, CardContent,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import Weekday from './Weekday';
import { convertSchedule, TO_LOCAL, TO_UTC } from '../../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    marginBottom: '2em',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function WeeklySchedule() {
  const classes = useStyles();

  const updateButton = useRef();
  const [cookies] = useCookies();

  const [loading, setLoading] = useState(true);
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);
  const [sunday, setSunday] = useState([]);

  useEffect(() => {
    (async function fetchSchedule() {
      const { data: { getPoshingSpree } } = await API.graphql(graphqlOperation(`
        query GetSchedule($email: AWSEmail!) {
          getPoshingSpree(email: $email) {
            schedule
          }
        }      
      `, { email: cookies.user.email }));

      const schedule = convertSchedule(JSON.parse(getPoshingSpree.schedule), TO_LOCAL);

      setMonday(schedule[0]);
      setTuesday(schedule[1]);
      setWednesday(schedule[2]);
      setThursday(schedule[3]);
      setFriday(schedule[4]);
      setSaturday(schedule[5]);
      setSunday(schedule[6]);

      setLoading(false);
    }());
  }, []);

  return (
    <div
      className={classes.root}
    >
      <div className={classes.heroContent}>
        <Container maxWidth="lg">
          <Typography component="h1" variant="h1" align="center" color="textPrimary" gutterBottom>
            How do you Posh?
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Set the schedule for your entire Poshmark operation! Once you've defined a
            {' '}
            <a style={{ color: '#FC2F89', textDecoration: 'none' }} href="#">
              strategy
            </a>
            {' '}
            (how you share, follow, offer, etc.), this is when we'll implement it.
            We designed a few templates,
            {' '}
            <span style={{ fontWeight: 600 }}>The Maximizer</span>
            {' '}
            shares like it's your last wish
            while
            {' '}
            <span style={{ fontWeight: 600 }}>The Civilian</span>
            {' '}
            serves the more meek amongst us. But this is
            {' '}
            <span style={{ fontStyle: 'italic' }}>your</span>
            {' '}
            schedule, make it work for you!
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button
                  onClick={() => {
                    updateButton.current.scrollIntoView({ behavior: 'smooth' });

                    setMonday([...Array(24).keys()]);
                    setTuesday([...Array(24).keys()]);
                    setWednesday([...Array(24).keys()]);
                    setThursday([...Array(24).keys()]);
                    setFriday([...Array(24).keys()]);
                    setSaturday([...Array(24).keys()]);
                    setSunday([...Array(24).keys()]);
                  }}
                  variant="contained"
                  color="primary"
                >
                  The Maximizer
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    updateButton.current.scrollIntoView({ behavior: 'smooth' });

                    setMonday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                    setTuesday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                    setWednesday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                    setThursday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                    setFriday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                    setSaturday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                    setSunday([8, 9, 10, 14, 15, 16, 18, 19, 20, 21]);
                  }}
                  variant="contained"
                  color="primary"
                >
                  The Civilian
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    updateButton.current.scrollIntoView({ behavior: 'smooth' });

                    setMonday([]);
                    setTuesday([]);
                    setWednesday([]);
                    setThursday([]);
                    setFriday([]);
                    setSaturday([]);
                    setSunday([]);
                  }}
                  variant="contained"
                  color="primary"
                >
                  I'll handle this, thanks
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Grid
        onClick={() => updateButton.current.scrollIntoView({ behavior: 'smooth' })}
        container
        spacing={3}
      >
        {loading && (
          <Grid item style={{ width: '100%', height: '40vh' }}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Skeleton variant="rect" height="34vh" />
              </CardContent>
            </Card>
          </Grid>
        )}

        {!loading && (
          <>
            <Grid item xs>
              <Weekday day="Monday" selected={monday} setSelected={setMonday} />
            </Grid>
            <Grid item xs>
              <Weekday day="Tuesday" selected={tuesday} setSelected={setTuesday} />
            </Grid>
            <Grid item xs>
              <Weekday day="Wednesday" selected={wednesday} setSelected={setWednesday} />
            </Grid>
            <Grid item xs>
              <Weekday day="Thursday" selected={thursday} setSelected={setThursday} />
            </Grid>
            <Grid item xs>
              <Weekday day="Friday" selected={friday} setSelected={setFriday} />
            </Grid>
            <Grid item xs>
              <Weekday day="Saturday" selected={saturday} setSelected={setSaturday} />
            </Grid>
            <Grid item xs>
              <Weekday day="Sunday" selected={sunday} setSelected={setSunday} />
            </Grid>
          </>
        )}

      </Grid>

      {!loading && (
        <Button
          ref={updateButton}
          onClick={async () => {
            await API.graphql(graphqlOperation(`
            mutation MyMutation($input: UpdatePoshingSpreeInput!) {
              updatePoshingSpree(input: $input) {
                active
              }
            }
          `, {
              input: {
                email: cookies.user.email,
                schedule: JSON.stringify(convertSchedule([
                  monday, tuesday, wednesday,
                  thursday, friday, saturday, sunday,
                ], TO_UTC)),
              },
            }));
          }}
          fullWidth
          style={{ marginTop: '1em' }}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      )}

    </div>
  );
}
