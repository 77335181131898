import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Login } from './auth';
import { Plan, Success, Register } from './auth/signup';

import Dashboard from './dashboard/layout';

export default function App() {
  return (
    <Switch>
      <Route exact path={['/login', '/start']} component={Login} />
      <Route exact path={['/signup', '/register']} component={Register} />
      <Route exact path={['/success']} component={Success} />
      <Route exact path={['/plan']} component={Plan} />
      <Route path={['/', '/dashboard']} component={Dashboard} />
    </Switch>
  );
}
