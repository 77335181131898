import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useCookies } from 'react-cookie';
import { Grid } from '@material-ui/core';

import Chart from './Chart';
import Timeline from './Timeline';

export default function Welcome() {
  const [cookies] = useCookies();

  return (
    <Grid direction="column" container>
      <Card elevation={3}>
        <CardContent style={{ padding: '2em' }}>
          <Typography style={{ fontWeight: 500 }} component="h2" variant="h5">
            Hi,
            {' '}
            {cookies.user.name}
            !
          </Typography>
          <Typography style={{ fontWeight: 'normal', opacity: 0.6 }} variant="subtitle1" paragraph>
            Welcome back to your dashboard!
          </Typography>
        </CardContent>
      </Card>
      <Grid style={{ marginTop: '2em' }} justify="space-evenly" direction="row" container spacing={3}>
        <Grid item xs={6}>
          <Chart />
        </Grid>
        <Grid item xs={6}>
          <Timeline />
        </Grid>
      </Grid>
    </Grid>
  );
}
