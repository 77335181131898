import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import MuiAlert from '@material-ui/lab/Alert';

import { API, Auth, graphqlOperation } from 'aws-amplify';

import {
  Box,
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Button,
  FormControl,
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import { PulseLoader } from 'react-spinners';
import LoginImage from '../../images/illustrations/monitor.svg';

export default function Login() {
  // Cookies
  const [cookies, setCookie] = useCookies();

  // Alerts
  const [errorState, setErrorState] = useState(false);
  const [loggingInState, setLoggingInState] = useState(false);

  // Fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (cookies.user) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Container maxWidth="lg">
                    <Grid container spacing={5}>
                      <Grid
                        item
                        xs={12}
                        lg={5}
                        className="d-none d-xl-flex align-items-center"
                      >
                        <img
                          alt="..."
                          className="w-100 mx-auto d-block img-fluid"
                          src={LoginImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={7}
                        className="d-flex flex-column align-items-center"
                      >
                        <span className="w-100 text-left text-md-center pb-4">
                          <Box flexDirection="row" display="flex">
                            <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                              Let's get you plugged back in
                            </h1>
                            {loggingInState && <Box padding="1em"><PulseLoader /></Box>}
                          </Box>
                          <p className="font-size-lg text-xl-left text-center mb-0 text-black-50">
                            Login to keep poshing! Don't know us yet?
                            {' '}
                            <a style={{ color: '#FC2F89' }} href="/signup">Signup for free!</a>
                          </p>
                        </span>
                        <Card className="m-0 w-100 p-0 border-0">
                          <CardContent className="p-3">
                            <form
                              className="px-5"
                              onSubmit={async (event) => {
                                event.preventDefault();

                                setLoggingInState(true);

                                try {
                                  const user = await Auth.signIn(email, password);
                                  console.log(user);
                                  setCookie('user', { ...user.attributes });
                                } catch (error) {
                                  setErrorState(true);
                                  setLoggingInState(false);
                                  console.log('error signing in', error);
                                }
                              }}
                            >
                              <div className="mb-3">
                                <FormControl className="w-100">
                                  <InputLabel htmlFor="input-with-icon-adornment">
                                    Email address
                                  </InputLabel>
                                  <Input
                                    onFocus={() => setErrorState(false)}
                                    onChange={({ target }) => setEmail(target.value)}
                                    color="primary"
                                    fullWidth
                                    id="input-with-icon-adornment"
                                    startAdornment={(
                                      <InputAdornment position="start">
                                        <MailOutlineTwoToneIcon />
                                      </InputAdornment>
                                    )}
                                  />
                                </FormControl>
                              </div>
                              <div className="mb-3">
                                <FormControl className="w-100">
                                  <InputLabel htmlFor="standard-adornment-password">
                                    Password
                                  </InputLabel>
                                  <Input
                                    onFocus={() => setErrorState(false)}
                                    onChange={({ target }) => setPassword(target.value)}
                                    color="primary"
                                    id="standard-adornment-password"
                                    fullWidth
                                    type="password"
                                    startAdornment={(
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    )}
                                  />
                                </FormControl>
                              </div>
                              <div className="w-100">
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      value="checked1"
                                      color="primary"
                                    />
                                  )}
                                  label="Remember me"
                                />
                              </div>
                              <div className="text-center">
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  size="large"
                                  className="my-2"
                                >
                                  Sign in
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    {errorState && (
                      <MuiAlert style={{ margin: 'auto', marginTop: '30px', width: '50%' }} className="mb-4" severity="error">
                        <div className="d-flex align-items-center align-content-center">
                          <span>
                            <strong className="d-block">Whoops!</strong>
                            We can't seem to find you! Maybe you don't have an account yet?
                            {' '}
                            <a href="/signup">Signup here!</a>
                          </span>
                        </div>
                      </MuiAlert>
                    )}
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
