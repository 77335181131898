import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, createMuiTheme, ThemeProvider } from '@material-ui/core';

import Amplify from 'aws-amplify';
import App from './components/App';

import { PaymentContext } from './context';

// eslint-disable-next-line no-undef
const stripe = Stripe('pk_live_51HZsEnL4UchV9X7gghNmsNgXIUUEN0MVHKEw5p7I4WX0D2So5u4zC6LLxCOCWE18MFQ4va65VnwMhzUGh5TF4Dkv0053cW9KA9');

const theme = createMuiTheme({
  palette: {
    primary: { main: '#FC2F89' }, // Wild Strawberry
    secondary: { main: '#FCE4F0' }, // Wisp Pink
    info: { main: '#FC18C6' }, // Shocking Pink
  },
});

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_lmVygZ7dF',
    userPoolWebClientId: '63qulmlmkjaqt6pbvbbuoue9i9',
  },

  aws_appsync_graphqlEndpoint: 'https://xfe7txb4tbcqjjdrtq2u5klvhq.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

ReactDOM.render(
  <CookiesProvider>
    <PaymentContext.Provider value={stripe}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </PaymentContext.Provider>
  </CookiesProvider>,
  document.getElementById('root'),
);
