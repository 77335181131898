import React, { useContext, useEffect, useState } from 'react';
import arrayMove from 'array-move';

import {
  Container, InputAdornment, makeStyles, TextField,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { API, graphqlOperation } from 'aws-amplify';
import { useCookies } from 'react-cookie';
import { SpreeContext } from '../../../context';
import ClosetGrid from './ClosetGrid';
import { useInterval } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Closet() {
  const classes = useStyles();
  const [closet, setCloset] = useState([]);
  const [cookies] = useCookies();

  useInterval(async () => {
    const { data: { getPoshingSpree: spree } } = await API.graphql(graphqlOperation(`
      query GetAttributes($email: AWSEmail!) {
        getPoshingSpree(email: $email) { closet }
      }
    `, { email: cookies.user.email }));

    setCloset(JSON.parse(spree.closet));
  }, 15000);

  return !closet.length ? null : (
    <main>
      {/* Hero unit */}
      <Container maxWidth="lg" className="py-4">
        <div className="d-flex justify-content-between">
          <div className="text-black">
            <h1 className="display-3 mb-2 font-weight-bold">My Closet</h1>
            <p className="font-size-lg text-black-50">
              Search for an item below to rearrange it or change its settings
            </p>
          </div>
        </div>
      </Container>
      {/* <List /> */}
      <div className="app-search-wrapper">
        <Container maxWidth="lg">
          <TextField
            className="app-search-input"
            fullWidth
            label="Search…"
            placeholder="What can we help you find?"
            variant="outlined"
            InputProps={{
              'aria-label': 'search',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="app-search-icon" />
                </InputAdornment>
              ),
            }}
          />
        </Container>
      </div>
      <Container style={{ overflow: 'auto' }} className={classes.cardGrid} maxWidth="md">
        <ClosetGrid
          distance={10}
          items={closet}
          axis="xy"
          onSortEnd={async ({ oldIndex, newIndex }) => {
            setCloset(arrayMove(closet, oldIndex, newIndex));

            await API.graphql(graphqlOperation(`
                mutation MyMutation($input: UpdatePoshingSpreeInput!) {
                  updatePoshingSpree(input: $input) {
                    active
                  }
                }
              `, {
              input: {
                email: cookies.user.email,
                closet: JSON.stringify(arrayMove(closet, oldIndex, newIndex)),
              },
            }));
          }}
        />
      </Container>
    </main>
  );
}
