import React, { useState } from 'react';

import {
  Paper, Typography, Box, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paper: {
    '&:hover': {
      cursor: 'pointer',
    },
    userSelect: 'none',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Weekday({ day, selected, setSelected }) {
  const classes = useStyles();
  const [hover, setHover] = useState(null);
  const [dragSelected, setDragSelected] = useState([]);

  return (
    <Paper
      onMouseLeave={() => {
        setHover(null);
        setDragSelected([]);
      }}
      className={classes.paper}
    >
      {day}
      <Box style={{ marginTop: '1em' }}>
        {
          [
            '12AM',
            '1AM',
            '2AM',
            '3AM',
            '4AM',
            '5AM',
            '6AM',
            '7AM',
            '8AM',
            '9AM',
            '10AM',
            '11AM',
            '12PM',
            '1PM',
            '2PM',
            '3PM',
            '4PM',
            '5PM',
            '6PM',
            '7PM',
            '8PM',
            '9PM',
            '10PM',
            '11PM',
          ].map((time, id) => (
            <Box
              key={time}
              onMouseEnter={() => {
                setHover(id);

                if (dragSelected.length) {
                  const newDragSelected = [];

                  let i = dragSelected[0];
                  while (i !== id) {
                    newDragSelected.push(i);
                    i += Math.sign(id - i);
                  }

                  setDragSelected(newDragSelected.concat(id));
                }
              }}
              onMouseDown={() => {
                setDragSelected([id]);

                if (!selected.includes(id)) {
                  setSelected(selected.concat(id));
                } else {
                  setSelected(selected.filter((i) => id !== i));
                  setDragSelected([]);
                  setHover(null);
                }
              }}
              onMouseUp={() => {
                setSelected([...new Set(dragSelected.concat(selected))]);
                setDragSelected([]);
              }}
              borderTop={1}
              borderColor="#aaaaaa"
              className={classes.box}
              style={{
                background: (selected.includes(id) || dragSelected.includes(id) || hover === id) && '#FCE4F0',
              }}
              height="2em"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Typography style={{ userSelect: 'none', marginTop: '-1.5em', fontSize: '0.6em' }} variant="subtitle1">
                {time}
              </Typography>
            </Box>
          ))
        }
      </Box>
    </Paper>
  );
}
