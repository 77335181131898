import React, { useState } from 'react';
import moment from 'moment';

import { Card } from '@material-ui/core';

import { API, graphqlOperation } from 'aws-amplify';
import { useCookies } from 'react-cookie';
import { useInterval } from '../../../hooks';
import { convertSchedule, TO_LOCAL } from '../../../utils/date';

export default function Timeline() {
  const [cookies] = useCookies();
  const [goal, setGoal] = useState([]);
  const [closet, setCloset] = useState([]);
  const [active, setActive] = useState(true);

  const refs = {};

  useInterval(async () => {
    if (!goal.length || !closet.length) {
      const { data: { getPoshingSpree: spree } } = await API.graphql(graphqlOperation(`
      query GetAttributes($email: AWSEmail!) {
        getPoshingSpree(email: $email) { 
          goal
          schedule 
          closet
        }
      }`, { email: cookies.user.email }));

      const schedule = convertSchedule(JSON.parse(spree.schedule), TO_LOCAL);

      setActive(schedule[(moment().weekday() + 6) % 7].includes(moment().hour()));
      setGoal(JSON.parse(spree.goal));
      setCloset(JSON.parse(spree.closet));
    }
  }, 60000);

  useInterval(() => {
    if (!Object.keys(refs).length) {
      return;
    }

    const now = moment().minute();

    let action = goal[goal.length - 1];
    let closestNegativeToZero = -Infinity;

    for (let i = 1; i < goal.length; i += 1) {
      const delta = now - goal[i];
      if (delta > closestNegativeToZero && delta < 0) {
        closestNegativeToZero = delta;
        action = goal[i];
      }
    }

    refs[action].scrollIntoView({ behavior: 'smooth' });
    setGoal(goal.slice());
    setCloset(closet.slice());
  }, 1000);

  return (
    <Card elevation={3}>
      <div className="card-header-alt px-4 pt-4 pb-0">
        <h6 className="font-weight-bold font-size-lg mb-1 text-black">
          Timeline
        </h6>
        <p className="text-black-50 mb-0">
          See what was, what is, and what will be
        </p>
      </div>

      <div style={{ marginTop: '1em', height: '463px', overflow: 'auto' }} className="timeline-list timeline-list-offset timeline-list-offset-dot mb-4">
        {!active && <div>Looks like your're not sharing at the moment. You can change this in your schedule!</div>}
        {active && goal.map((action) => (
          <div ref={(element) => { refs[action] = element; }} key={action} className="timeline-item">
            <div className="timeline-item-offset">{moment().startOf('hour').add(action, 'minutes').format('h:mm')}</div>
            <div className="timeline-item--content">
              <div style={{ backgroundColor: action <= moment().minute() ? '#1bc943' : '#f4772e' }} className="timeline-item--icon" />
              <h4 className="timeline-item--label mb-2 font-weight-bold">
                Share closet
              </h4>
              <p>
                These are the items that
                {' '}
                {action > moment().minute() ? 'will be' : 'have been'}
                {' '}
                shared
              </p>
              <div className="mt-3">
                {closet.map((item) => (
                  <a key={item.id} href="#/" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img-fluid rounded mr-3 shadow-sm"
                      src={item.picture_url}
                      width="70"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

    </Card>
  );
}
