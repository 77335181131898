import moment from 'moment';

export const TO_UTC = 1;
export const TO_LOCAL = -1;
export const DAYS_IN_WEEK = 7;

export function tzOffset(hour, direction) {
  return hour - direction * (moment().utcOffset() / 60);
}

export function convertSchedule(schedule, direction) {
  const newSchedule = {
    0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
  };

  if (!schedule) return newSchedule;

  for (let i = 0; i < DAYS_IN_WEEK; i += 1) {
    const hours = schedule[i];

    for (let j = 0; j < hours.length; j += 1) {
      const hourUTC = tzOffset(hours[j], direction);

      if (hourUTC > 23) {
        newSchedule[(i + 1) % DAYS_IN_WEEK].push(hourUTC % 24);
      } else if (hourUTC < 0) {
        newSchedule[(i + 6) % DAYS_IN_WEEK].push(24 + hourUTC);
      } else {
        newSchedule[i].push(hourUTC);
      }
    }
  }

  return Object.entries(newSchedule).reduce((sortedSchedule, [day, hours]) => ({
    ...sortedSchedule, [day]: hours.sort((a, b) => a - b),
  }), {});
}
