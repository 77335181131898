import React from 'react';
import { Grid } from '@material-ui/core';
import { SortableContainer } from 'react-sortable-hoc';

import ClosetItem from './ClosetItem';

export default SortableContainer(({ items }) => (
  <Grid container spacing={4}>
    {items.map((item, index) => (
      <ClosetItem key={item.id} index={index} item={item} />
    ))}
  </Grid>
));
